import React from "react"
import PropTypes from "prop-types"
import { Carousel } from "react-bootstrap"
import Img from "gatsby-image"

import "./carousel.scss"

const PhotosCarousel = ({ images, current }) => {
  const carouselItems = images.map((image, index) => (
    <Carousel.Item key={index}>
      <div className="imageContainer">
        <Img
          className="imageSlide img-fluid"
          fluid={image.node.childImageSharp.fluid}
        />
      </div>
    </Carousel.Item>
  ))

  return (
    <Carousel
      id="photosCarousel"
      interval={null}
      indicators={false}
      defaultActiveIndex={current}
      slide={false}
    >
      {carouselItems}
    </Carousel>
  )
}

PhotosCarousel.propTypes = {
  images: PropTypes.array.isRequired,
  current: PropTypes.number.isRequired,
}

export default PhotosCarousel
