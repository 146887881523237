import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import NavigationBar from "./navigation-bar"
import "./layout.scss"

function Layout(props) {
  const [activeNavbar, setActiveNavbar] = useState()
  const changeBackground = () => {
    if (window.scrollY >= 50) {
      setActiveNavbar(true)
    } else {
      setActiveNavbar(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", changeBackground)
    return () => {
      window.removeEventListener("scroll", changeBackground)
    }
  }, [])

  return (
    <div
      className={
        activeNavbar ? "site-container navbar-scrolled" : "site-container"
      }
      id="page-top"
    >
      <NavigationBar />

      <main>{props.children}</main>
      <footer className="bg-primary py-3">
        <div className="container">
          <div className="text-uppercase text-white text-center">
            YaJa Boat Experience © {new Date().getFullYear()}
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Layout

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
