import React from "react"
import Carousel from "react-bootstrap/Carousel"
import Container from "react-bootstrap/Container"
import "./reviews.scss"
import styled from "styled-components"

const RevCarousel = styled(Carousel)`
  position: relative;
  height: 20rem;
  display: flex;
  align-items: center;
  .carousel-inner {
    display: flex;
    align-items: center;
  }

  .carousel-control-prev {
    width: 5%;
    .carousel-control-prev-icon {
      filter: invert(1) !important;
    }
  }
  .carousel-control-next {
    width: 5%;
    .carousel-control-next-icon {
      filter: invert(1) !important;
    }
  }
`

const Reviews = () => {
  const reviewsList = [
    {
      text:
        "“Yaniv and Nadja have been fantastic hosts. They can really show you the nice spots for swimming and sight seeing. Our children loved it especially the catering with all kind of drinks and snacks. Not to forget the ride on the funtube. Five stars and we can just recommend a boat tour with Yaniv and Nadja.”",
      author: "Stephan",
    },
    {
      text:
        "“We had a wonderful day on the water and since we were lucky with the weather also had a great swimming fun. The team is great and made you feel alone on board, but nevertheless you were 'taken care of'. Really great! The booking went straightforward and so we were able to easily escape mass tourism and get to know Berlin from a completely different side. Highly recommended and at any time again! Thanks to Yaniv and Nadja for this special opportunity”",
      author: "Treske Heinemann",
    },
    {
      text:
        "“Wonderful experience! The lake makes for a great get away for a relaxing day on the water. Nadja and Yaniv are very friendly and hospitable and make sure that we had a great time. The boat is comfortable and fully equipped, and the fun-tube was a real bonus. Thanks a lot for the wonderful day!”",
      author: "Shira Plantjée",
    },
    {
      text: "“Thank you very much guys!!! It was an amazing day!”",
      author: "Dan Opeskin",
    },
    {
      text:
        "“Nadja and Yaniv are super nice hosts! Had a great summer day on the boat. The fun ride was especially great for the kids!! Highly recommended! Thanks a lot!”",
      author: "Juliane Ambras",
    },
  ]

  return (
    <Container>
      <RevCarousel indicators={false}>
        {reviewsList.map(q => (
          <Carousel.Item
            id="revCarousel"
            className="text-center p-4"
            interval={5000}
          >
            <div className="slide">
              <i className="fas fa-quote-right qIcon"></i>

              <p className="text-muted">{q.text}</p>
              <h4>{q.author}</h4>
            </div>
          </Carousel.Item>
        ))}
      </RevCarousel>
    </Container>
  )
}

export default Reviews
