import React, { useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { scroller } from "react-scroll"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PhotosModal from "../components/photosgallery/modal"
import PhotosCarousel from "../components/photosgallery/carousel"
import { useIntl } from "gatsby-plugin-intl"
import Reviews from "../components/reviews"
import ContactForm from "../components/contactForm"
import Header from "../components/header"

function IndexPage(props) {
  const [modalShow, setModalShow] = useState(false)

  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
    setModalShow(true, index)
  }
  const intl = useIntl()
  return (
    <Layout>
      <SEO 
      lang={intl.locale}
      title={intl.formatMessage({ id: "title" })}
      description={intl.formatMessage({ id: "description" })} />
      <Header />
      <section className="page-section bg-primary" id="about">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h4 className="text-white mt-0">
                {intl.formatMessage({ id: "about_title_1" })}
                <br />
                <br />
                {intl.formatMessage({ id: "about_title_2" })}
              </h4>

              <hr className="divider light my-4" />
              <p className="text-white mb-4">
                {intl.formatMessage({ id: "about_text_1" })}
                <br />
                <br />
                {intl.formatMessage({ id: "about_text_2" })}
                <br />
                <br />
                {intl.formatMessage({ id: "about_text_3" })}
              </p>
              <div
                className="btn btn-custom btn-xl js-scroll-trigger"
                onClick={() => scroller.scrollTo("contact")}
              >
                {intl.formatMessage({ id: "about_button" })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="page-section" id="services">
        <div className="container">
          <h2 className="text-center mt-0">
            {intl.formatMessage({ id: "services_title" })}
          </h2>
          <hr className="divider my-4" />
          <div className="row">
            <div className="col-lg-3 col-md-6 text-center">
              <div className="mt-5">
                <i className="fas fa-4x fa-tree text-primary mb-4"></i>
                <h3 className="h4 mb-2">
                  {intl.formatMessage({ id: "service_1" })}
                </h3>
                <p className="text-muted mb-0">
                  {intl.formatMessage({ id: "service_1_text" })}
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 text-center">
              <div className="mt-5">
                <i className="fas fa-4x fa-ship text-primary mb-4"></i>
                <h3 className="h4 mb-2">
                  {intl.formatMessage({ id: "service_2" })}
                </h3>
                <p className="text-muted mb-0">
                  <li>{intl.formatMessage({ id: "service_2_text1" })}</li>
                  <li>{intl.formatMessage({ id: "service_2_text2" })}</li>
                  <li>{intl.formatMessage({ id: "service_2_text3" })}</li>
                  <li>{intl.formatMessage({ id: "service_2_text4" })}</li>
                  <li>{intl.formatMessage({ id: "service_2_text5" })}</li>
                  <li>{intl.formatMessage({ id: "service_2_text6" })}</li>
                  <li>{intl.formatMessage({ id: "service_2_text7" })}</li>
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 text-center">
              <div className="mt-5">
                <i className="fas fa-4x fa-utensils text-primary mb-4"></i>
                <h3 className="h4 mb-2">
                  {intl.formatMessage({ id: "service_3" })}
                </h3>
                <p className="text-muted mb-0">
                  {intl.formatMessage({ id: "service_3_text" })}
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 text-center">
              <div className="mt-5">
                <i className="fas fa-4x fa-swimmer text-primary mb-4"></i>
                <h3 className="h4 mb-2">{intl.formatMessage({ id: "service_4" })}</h3>
                <p className="text-muted mb-0">
                  {intl.formatMessage({ id: "service_4_text" })}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="page-section" id="portfolio">
        <div className="container p-0">
          <div className="row no-gutters">
            <div className="col-lg-3 col-sm-6 p-0">
              <div className="img-overlay" onClick={() => handleSelect(0)}>
                <Img
                  className="img-transform center"
                  fluid={props.data.images.edges[0].node.childImageSharp.fluid}
                />
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 p-0">
              <div className="img-overlay" onClick={() => handleSelect(1)}>
                <Img
                  className="img-transform center"
                  fluid={props.data.images.edges[1].node.childImageSharp.fluid}
                />
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 p-0">
              <div className="img-overlay" onClick={() => handleSelect(2)}>
                <Img
                  className="img-transform center"
                  fluid={props.data.images.edges[2].node.childImageSharp.fluid}
                />
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 p-0">
              <div className="img-overlay" onClick={() => handleSelect(3)}>
                <Img
                  className="img-transform center"
                  fluid={props.data.images.edges[3].node.childImageSharp.fluid}
                />
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-lg-3 col-sm-6 p-0">
              <div className="img-overlay" onClick={() => handleSelect(4)}>
                <Img
                  className="img-transform center"
                  fluid={props.data.images.edges[4].node.childImageSharp.fluid}
                />
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 p-0">
              <div className="img-overlay" onClick={() => handleSelect(5)}>
                <Img
                  className="img-transform center"
                  fluid={props.data.images.edges[5].node.childImageSharp.fluid}
                />
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 p-0">
              <div className="img-overlay" onClick={() => handleSelect(6)}>
                <Img
                  className="img-transform center"
                  fluid={props.data.images.edges[6].node.childImageSharp.fluid}
                />
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 p-0">
              <div className="img-overlay" onClick={() => handleSelect(7)}>
                <Img
                  className="img-transform center"
                  fluid={props.data.images.edges[7].node.childImageSharp.fluid}
                />
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-lg-3 col-sm-6 p-0">
              <div className="img-overlay" onClick={() => handleSelect(8)}>
                <Img
                  className="img-transform center"
                  fluid={props.data.images.edges[8].node.childImageSharp.fluid}
                />
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 p-0">
              <div className="img-overlay" onClick={() => handleSelect(9)}>
                <Img
                  className="img-transform center"
                  fluid={props.data.images.edges[9].node.childImageSharp.fluid}
                />
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 p-0">
              <div className="img-overlay" onClick={() => handleSelect(10)}>
                <Img
                  className="img-transform center"
                  fluid={props.data.images.edges[10].node.childImageSharp.fluid}
                />
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 p-0">
              <div className="img-overlay" onClick={() => handleSelect(11)}>
                <Img
                  className="img-transform center"
                  fluid={props.data.images.edges[11].node.childImageSharp.fluid}
                />
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-lg-3 col-sm-6 p-0">
              <div className="img-overlay" onClick={() => handleSelect(12)}>
                <Img
                  className="img-transform center"
                  fluid={props.data.images.edges[12].node.childImageSharp.fluid}
                />
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 p-0">
              <div className="img-overlay" onClick={() => handleSelect(13)}>
                <Img
                  className="img-transform center"
                  fluid={props.data.images.edges[13].node.childImageSharp.fluid}
                />
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 p-0">
              <div className="img-overlay" onClick={() => handleSelect(14)}>
                <Img
                  className="img-transform center"
                  fluid={props.data.images.edges[14].node.childImageSharp.fluid}
                />
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 p-0">
              <div className="img-overlay" onClick={() => handleSelect(15)}>
                <Img
                  className="img-transform center"
                  fluid={props.data.images.edges[15].node.childImageSharp.fluid}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="page-section mobile-padding" id="reviews">
        <Reviews />
      </section>

      <section className="page-section" id="contact">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="mt-0">
                {intl.formatMessage({ id: "booking_title" })}
              </h2>
              <hr className="divider my-4" />
            </div>
          </div>
          <ContactForm />
        </div>
      </section>

      <PhotosModal show={modalShow} onHide={() => setModalShow(false, 0)}>
        <PhotosCarousel images={props.data.images.edges} current={index} />
      </PhotosModal>
    </Layout>
  )
}
export default IndexPage

export const imageData = graphql`
  query {
    images: allFile(
      filter: { relativePath: { glob: "portfolio/fullsize/*.jpg" } }
      sort: { fields: modifiedTime }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
