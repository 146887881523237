import React from "react"
import { Navbar, Nav } from "react-bootstrap"
import { Link, scroller } from "react-scroll"
import { useIntl } from "gatsby-plugin-intl"
import Language from "./language"
import Logo from "./Logo"

function NavigationBar() {
  const intl = useIntl()
  return (
    <>
      <Navbar
        className="navbar navbar-expand-lg navbar-light fixed-top py-3"
        id="mainNav"
        expand="lg"
        collapseOnSelect={true}
      >
        <div className="container">
          <a href="#page-top">
            <Logo />
          </a>
          <a className="navbar-brand js-scroll-trigger" href="#page-top">
            YAJA Boat Experience
          </a>
          <Navbar.Toggle aria-controls="navbarResponsive" />
          <Navbar.Collapse id="navbarResponsive">
            <Nav className="navbar-nav ml-auto my-2 my-lg-0">
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  className={"js-scroll-trigger"}
                  to="about"
                  href="#about"
                  offset={30}
                >
                  {intl.formatMessage({ id: "nav_link_1" })}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  className={"js-scroll-trigger"}
                  to="services"
                  href="#services"
                  offset={30}
                >
                  {intl.formatMessage({ id: "nav_link_2" })}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  className={"js-scroll-trigger"}
                  to="portfolio"
                  href="#portfolio"
                  offset={50}
                >
                  {intl.formatMessage({ id: "nav_link_3" })}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  className={"js-scroll-trigger"}
                  to="reviews"
                  href="#reviews"
                  offset={-20}
                >
                  {intl.formatMessage({ id: "nav_link_4" })}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  className={"nav-link js-scroll-trigger"}
                  to="contact"
                  href="#contact"
                  offset={30}
                >
                  {intl.formatMessage({ id: "nav_link_5" })}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Language />
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  )
}
export default NavigationBar
