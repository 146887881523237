import React from "react"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
// import FormModal from "./thank-you-modal/form-modal"

// import { graphql } from "gatsby"
// import Img from "gatsby-image"

const StyledForm = styled(Form)`
  .form-control {
    border-radius: 0.5rem;
    padding: 1rem;
  }
  .form-label {
    margin-left: 0.5rem;
  }
`

const H1 = styled.h1`
  color: #51adcf;
`

const ContactForm = () => {
  // const [modalShow, setModalShow] = React.useState(false)

  // const handleShow = () => setModalShow(true)
const intl = useIntl()
  return (
    <div>
      <Row className="mt-3">
        <Col className="col-md-7 col-12 text-center mb-3">
          <Row className="justify-content-center">
            <div>
              <H1>{intl.formatMessage({ id: "contact_title" })}</H1>
              <p className="text-muted">Am Pichelssee 37 Berlin</p>
              <Row>
                <Col>
                  <i className="fas fa-phone fa-2x mb-3 text-muted" />
                  <p>
                    <a href="tel:+49-0176-45884245">+49 176 45884245</a>
                  </p>
                </Col>
                <Col>
                  <i className="fas fa-envelope fa-2x mb-3 text-muted" />
                  <p>
                    <a href="mailto:yajaboat@gmail.com">yajaboat@gmail.com</a>
                  </p>
                </Col>
              </Row>
            </div>
          </Row>
          <Row className="m-0 pt-2">
            <iframe
              className="iframe-map"
              frameborder="0"
              src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJvR2BUbtXqEcReKeQi1iuATQ&key=AIzaSyAaOESz6CYa6XdnoCwDMEAae6g7_izdups"
              allowfullscreen
            ></iframe>
          </Row>
        </Col>
        <Col className="col-md-5 col-12">
          <StyledForm
            name="contact form"
            method="post"
            data-netlify-honeypot="bot-field"
            data-netlify="true"
            action="/thank-you"
          >
            <input type="hidden" name="form-name" value="contact form" />
            <p hidden>
              <label>
                Don’t fill this out
                <input name="bot-field" />
              </label>
            </p>

            <Form.Group controlId="fullName">
              <Form.Label>{intl.formatMessage({ id: "contact_name" })}</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder={intl.formatMessage({ id: "contact_name" })}
                name="full-name"
              />
            </Form.Group>
            <Form.Group controlId="date">
              <Form.Label>{intl.formatMessage({ id: "contact_date" })}</Form.Label>
              <Form.Control required type="date" name="date" placeholder={intl.formatMessage({ id: "contact_date" })} />
            </Form.Group>
            <Row>
              <Col>
                <Form.Group controlId="email">
                  <Form.Label>{intl.formatMessage({ id: "contact_email" })}</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    placeholder={intl.formatMessage({ id: "contact_email" })}
                    name="email"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="phone">
                  <Form.Label>{intl.formatMessage({ id: "contact_phone" })}</Form.Label>
                  <Form.Control type="tel" placeholder={intl.formatMessage({ id: "contact_phone" })} name="phone" />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group controlId="message">
              <Form.Label>{intl.formatMessage({ id: "contact_message" })}</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                placeholder={intl.formatMessage({ id: "contact_message" })}
                name="message"
              />
            </Form.Group>
            <div className="d-flex justify-content-center">
              <Button className="btn-lg w-35" variant="info" type="submit">
                {intl.formatMessage({ id: "contact_button" })}
              </Button>
            </div>
          </StyledForm>
        </Col>
      </Row>
      {/* <FormModal show={modalShow} onHide={() => setModalShow(false)} /> */}
    </div>
  )
}

export default ContactForm
