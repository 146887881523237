import React from "react"
import PropTypes from "prop-types"
import { Modal } from "react-bootstrap"
import "./modal.scss"

function PhotosModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>

      <Modal.Body>{props.children}</Modal.Body>
    </Modal>
  )
}

PhotosModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default PhotosModal
