import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import { NavDropdown } from "react-bootstrap"
import Image from "react-bootstrap/Image"
import icon from "../images/translate-icon.png"

const languageName = {
  en: "English",
  de: "Deutsch",
}

const Language = () => {
  return (
    <div>
      <NavDropdown
        // className="pl-2"
        title={
          <Image
            src={icon}
            style={{
              width: 16,
            }}
          />
        }
        id="basic-nav-dropdown"
      >
        <IntlContextConsumer>
          {({ languages, language: currentLocale }) =>
            languages.map((language, index) => (
              <NavDropdown.Item
                className="pl-2 pr-2"
                key={language}
                id={index}
                onClick={() => {
                  changeLocale(language)
                }}
                style={{
                  // color: currentLocale === language ? `#fffef7` : `$gray-900;`,
                  margin: 0,
                  cursor: `pointer`,
                }}
              >
                {languageName[language]}
              </NavDropdown.Item>
            ))
          }
        </IntlContextConsumer>
      </NavDropdown>
    </div>
  )
}

export default Language
