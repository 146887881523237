import React from "react"
import { scroller } from "react-scroll"
import { useIntl } from "gatsby-plugin-intl"

function Header() {
  const intl = useIntl()
  return (
    <>
      <header className="masthead">
        <div className="container h-100">
          <div className="row h-100 align-items-center justify-content-center text-center">
            <div className="col-lg-10 align-self-end">
              <h1 className="text-uppercase text-white font-weight-bold">
                Yaja Boat Experience Berlin{" "}
              </h1>
              <hr className="divider my-4" />
            </div>
            <div className="col-lg-8 align-self-baseline">
              <h5 className="text-white font-weight-bold">
                {intl.formatMessage({ id: "header.line1" })}
              </h5>
              <h5 className="text-white font-weight-bold mb-5">
                {intl.formatMessage({ id: "header.line2" })}
              </h5>
              <div
                role="button"
                tabIndex={0}
                className="btn btn-primary btn-xl js-scroll-trigger"
                onClick={() => scroller.scrollTo("about", { offset: 30 })}
                onKeyPress={() => scroller.scrollTo("about", { offset: 30 })}
              >
                {intl.formatMessage({ id: "header_button" })}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
export default Header
